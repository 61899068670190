
import { computed, defineComponent, PropType, ref, Ref } from 'vue';
import InputText from '@/components/Input/Text/InputText.vue';
import Button from '@/components/Button/Button.vue';
import { TableHeader } from './model/TableHeader';
import { Size } from '@/models/components/size.model';

export default defineComponent({
  name: 'Table',
  components: {
    InputText,
    Button,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    headers: {
      type: Array as PropType<TableHeader[]>,
      default: () => [],
    },
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    paginator: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'Empty table',
    }, 
    loadingText: {
      type: String,
      default: 'loading',
    }, 
    size: {
      type: String as PropType<Size>,
      default: 'md',
    },
  },
  setup(props) {
    const search: Ref<string> = ref('');
    const sortKey: Ref<string> = ref('');
    const sortDesc: Ref<boolean> = ref(true);
    
    const fiteredItems = computed(() => {
      let items = props.items.slice(0);
      if (sortKey.value) {
        items.sort((a, b) => (a[sortKey.value] < b[sortKey.value]) ? 1 : -1);
        if (sortDesc.value) items.reverse();
      }
      if (search.value) {
        const condition = search.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        return items.filter(item => JSON.stringify(item).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(condition) !== -1);
      }
      return items;
    });

    function sortIconByheader(itemKey: string): string {
      if (sortKey.value === itemKey) {
        if (sortDesc.value) {
          return 'sort-down';
        } else {
          return 'sort-up';
        }
      }
      return 'sort';
    }

    function setSortKey(itemKey: string | undefined): void {
      if (itemKey) {
        if (sortKey.value != itemKey) {
          sortDesc.value = true;
          sortKey.value = itemKey;
        } else {
          sortKey.value = itemKey;
          if (sortDesc.value) {
            sortDesc.value = false;
          } else {
            sortKey.value = '';
          }
        }
      }
    }

    return {
      fiteredItems,
      search,
      sortIconByheader,
      setSortKey,
    };
  },
});
